'use strict'

var Mappy = require('./L.Mappy')
var L = (typeof window !== "undefined" ? window['L'] : typeof global !== "undefined" ? global['L'] : null)
var decorateWithDeprecationHandling = require('./tools/RouteAPIDeprecation')

function check (obj, attr, message) {
  if (!obj.hasOwnProperty(attr)) {
    throw new Error(message)
  }
}

function removeUnwantedProperties (route) {
  route.routes.forEach(function (r) {
    delete r.gps_link_template
    delete r.gps_label
    delete r.roadbook_link
    delete r.gps_query_params
  })
  return route
}

function geocode (options, successCallback, failureCallback) {
  Mappy._checkClientId()
  Mappy.cors({
    url: options.url,
    data: options.data,
    success: function (response) {
      if (response && response.code) {
        return failureCallback(response)
      }
      if (!response || !response.addresses || !response.addresses.features || response.addresses.features.length === 0) {
        return failureCallback('no_result')
      }
      successCallback(response.addresses.features)
    },
    error: failureCallback
  })
}

function coords (point) {
  function toArray () {
    if (point instanceof L.LatLng) {
      return [point.lng, point.lat]
    }
    return point.split(',').map(parseFloat)
  }

  return toArray(point).map(function (n) {
    return n.toFixed(6)
  }).join(',')
}

module.exports = {

  localeParameters: {
    fr_FR: {
      favoriteCountry: 250,
      language: 'fre'
    },
    en_GB: {
      favoriteCountry: 826,
      language: 'eng'
    },
    fr_BE: {
      favoriteCountry: 56,
      language: 'fre'
    },
    nl_BE: {
      favoriteCountry: 56,
      language: 'dut'
    }
  },

  _decodePolyline: function (encoded) {
    var len = encoded.length
    var tmp = []
    var decoded = []
    var index = 0
    var lat = 0
    var lng = 0
    var decodeNextPoint = function () {
      var b
      var shift = 0
      var result = 0
      do {
        // get binary encodings
        b = encoded.charCodeAt(index++) - 63
        // binary shift
        result |= (b & 0x1f) << shift
        // move to next chunk
        shift += 5
      } while (b >= 0x20) // see if another binary value
      // if negative, flip bits & return
      return (((result & 1) > 0) ? ~(result >> 1) : (result >> 1))
    }

    while (index < len) {
      tmp.push(decodeNextPoint())
    }

    for (var i = 0; i < tmp.length; i += 2) {
      lat += tmp[i] * 1e-5
      lng += tmp[i + 1] * 1e-5
      decoded.push([lat, lng])
    }

    return decoded
  },

  geocodeReverse: function (latLng, successCallback, failureCallback) {
    if (latLng instanceof Array) {
      latLng = L.latLng(latLng[0], latLng[1])
    }

    geocode({
      url: Mappy._getGeocodeReverse(),
      data: {
        x: latLng.lng,
        y: latLng.lat
      }
    }, successCallback, failureCallback)
  },

  geocodeForward: function (query, successCallback, failureCallback) {
    geocode({
      url: Mappy._getGeocodeForward(),
      data: {
        limit: 10,
        q: query
      }
    }, successCallback, failureCallback)
  },

  route: function (options, successCallback, failureCallback) {
    Mappy._checkClientId()
    check(options, 'from', '1st argument should contains from as string (lon,lat)')
    check(options, 'to', '1st argument should to as string (lon,lat)')
    check(options, 'providers', '1st argument should contains providers as string')

    this._transports(options, L.bind(function (qid) {
      this._requestRoute(L.extend(options, {
        qid: qid
      }), function (response, deprecatedStatus) {
        successCallback(removeUnwantedProperties(response), deprecatedStatus)
      }, failureCallback)
    }, this), failureCallback)
  },

  roadbook: function (options, successCallback, failureCallback) {
    this._requestRoute(options, L.bind(function (response) {
      this._request(
        'cors',
        Mappy._getScheme() + '://routemm.' + Mappy._getDomain() + '/multipath/' + Mappy._getRouteAPIVersion() + response.routes[0].roadbook_link,
        undefined,
        successCallback,
        failureCallback
      )
    }, this), function (e) {
      failureCallback(e)
    })
  },

  _transports: function (options, successCallback, failureCallback) {
    options = options || {}
    var version = options.version
    var domain = options.domain
    delete options.version
    delete options.domain
    var data = L.extend(options, {
      'clientid': Mappy._getClientId(),
      lang: Mappy.getLocale()
    }, {
      from: coords(options.from),
      to: coords(options.to)
    }, options)

    this._request(
      'cors',
      Mappy._getScheme() + '://routemm.' + (domain || Mappy._getDomain()) + '/multipath/' + (version || Mappy._getRouteAPIVersion()) + '/transports',
      data,
      function (response, deprecatedStatus) {
        successCallback(response.qid, deprecatedStatus)
      },
      failureCallback
    )
  },

  _requestRoute: function (options, successCallback, failureCallback) {
    var data = L.extend(options || {}, {
      'clientid': Mappy._getClientId(),
      lang: Mappy.getLocale()
    }, {
      from: coords(options.from),
      to: coords(options.to),
      providers: options.providers
    }, options)

    this._request(
      'cors',
      Mappy._getScheme() + '://routemm.' + Mappy._getDomain() + '/multipath/' + Mappy._getRouteAPIVersion() + '/routes',
      data,
      function (response, deprecatedStatus) {
        if (!response || !response[data.providers] || !response[data.providers].routes || response[data.providers].routes.length === 0) {
          return failureCallback('no_result', deprecatedStatus)
        }
        successCallback(response[data.providers], deprecatedStatus)
      },
      failureCallback
    )
  },

  _request: function (type, url, data, successCallback, failureCallback) {
    var decoratedCallbacks = decorateWithDeprecationHandling(successCallback, failureCallback)

    Mappy[type]({
      url: url,
      data: data,
      success: decoratedCallbacks.successCallback,
      error: decoratedCallbacks.failureCallback
    })
  }
}
