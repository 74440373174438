'use strict'

function objectToUri (obj) {
  var data, key, value
  data = []
  for (key in obj) {
    if (obj.hasOwnProperty(key)) {
      value = obj[key]
      data.push(window.encodeURIComponent(key) + '=' + window.encodeURIComponent(value))
    }
  }
  return data.join('&')
}

var translations = {
  fr: {
    'layers.traffic': 'Trafic',
    'layers.transport': 'Transports en commun',
    'layers.photo': 'Vue aérienne',
    'layers.hybrid': 'Afficher le nom des lieux',
    'layers.bicycle': 'Pistes cyclables',
    'trafficLegend.trafficAt': 'Trafic à {hours}:{minutes}',
    'trafficLegend.slow': 'ralenti',
    'trafficLegend.blocked': 'fermé',

    'vehicle.car': 'Voiture',
    'vehicle.walk': 'Piéton',
    'vehicle.bike': 'Vélo',
    'bikelegend.title': 'Pistes cyclables'
  },
  en: {
    'layers.traffic': 'Traffic',
    'layers.transport': 'Public transports',
    'layers.photo': 'Satellite photos',
    'layers.hybrid': 'Display locations names',
    'layers.bicycle': 'Bike lanes',
    'trafficLegend.trafficAt': 'Traffic at {hours}:{minutes}',
    'trafficLegend.slow': 'slow',
    'trafficLegend.blocked': 'congested',

    'vehicle.car': 'Car',
    'vehicle.walk': 'Pedestrian',
    'vehicle.bike': 'Bicycle',
    'bikelegend.title': 'Bicycle paths'
  },
  nl: {
    'layers.traffic': 'Verkeer',
    'layers.transport': 'Openbaar vervoer',
    'layers.photo': 'Luchtfoto\'s',
    'layers.hybrid': 'Locaties weergeven namen',
    'layers.bicycle': 'Fietspaden',
    'trafficLegend.trafficAt': 'Verkeer op {hours}:{minutes}',
    'trafficLegend.slow': 'langzaam',
    'trafficLegend.blocked': 'vastgelopen',

    'vehicle.car': 'Auto',
    'vehicle.midcar': 'Middelgrote auto',
    'vehicle.walk': 'Voetganger',
    'vehicle.bike': 'Fiets',
    'bikelegend.title': 'Fietspaden'
  }
}

module.exports = {
  version: '@@version',
  _domain: 'mappy.net',
  _token: 'g0ztPgTHGBpIzhtEqb8IVksxgvRO/VCtTwFgtyNXgrE1AkVLgdBHwFgwpQ55cR5jtxZX0O5W1nY=',
  _clientId: null,
  _https: document.location.protocol.substr(0, 5) === 'https',
  _locale: 'fr_FR',
  _imgPath: 'images/',
  _logs: true,
  _defaultRouteAPIVersion: '6.0',
  _tooltip: true,

  getText: function (key) {
    return translations[this._locale.substr(0, 2)][key] || ''
  },

  setLocale: function (locale) {
    if (locale !== 'fr_FR' && locale !== 'en_GB' && locale !== 'fr_BE' && locale !== 'nl_BE') {
      throw new Error('This locale is not available')
    }

    this._locale = locale
    return this
  },

  setImgPath: function (path) {
    this._imgPath = path
    return this
  },

  getImgPath: function () {
    return this._imgPath
  },

  getLocale: function () {
    return this._locale
  },

  setToken: function () {
    throw new Error('setToken is deprecated in favor of setClientId (refer to documentation)')
  },

  _getToken: function () {
    return this._token
  },

  enableLogs: function () {
    this._logs = true
    return this
  },

  disableLogs: function () {
    this._logs = false
    return this
  },

  isLogsEnabled: function () {
    return this._logs
  },

  enableTooltip: function () {
    this._tooltip = true
    return this
  },

  disableTooltip: function () {
    this._tooltip = false
    return this
  },

  isTooltipEnabled: function () {
    return this._tooltip
  },

  setClientId: function (clientId) {
    this._clientId = clientId

    if (this._clientId) {
      // Ping mothership with API version and clientId
      var pingUrl = this._getScheme() + '://log.' + this._getDomain() + '/log/1.0/ping/api-leaflet/' + this._clientId + '/' + this.version
      setTimeout(function () {
        (new Image()).src = pingUrl
      }, 1000)
    }
    return this
  },

  _getClientId: function () {
    return this._clientId
  },

  enableHttps: function () {
    this._https = true
    return this
  },

  disableHttps: function () {
    this._https = false
    return this
  },

  _getHttps: function () {
    return this._https
  },

  _getScheme: function () {
    return 'http' + (this._getHttps() ? 's' : '')
  },

  _getDomain: function () {
    return this._domain
  },

  _setDomain: function (domain) {
    this._domain = domain
    return this
  },

  _getGeocodeForward: function () {
    return 'http' + (this._getHttps() ? 's' : '') + '://geocode.' + this._getDomain() + '/forward/1.0/find'
  },

  _getGeocodeReverse: function () {
    return 'http' + (this._getHttps() ? 's' : '') + '://geocode.' + this._getDomain() + '/reverse/1.0/find'
  },

  _checkClientId: function () {
    if (!this._getClientId()) {
      throw new Error('ClientId is mandatory (refer to documentation).')
    }
  },

  _setRouteAPIVersion: function (routeAPIVersion) {
    this._routeAPIVersion = routeAPIVersion
    return this
  },

  _getRouteAPIVersion: function () {
    return this._routeAPIVersion || this._defaultRouteAPIVersion
  },

  _resetRouteAPIToDefaultVersion: function () {
    this._routeAPIVersion = this._defaultRouteAPIVersion
    return this
  },

  /**
   * CORS request using XMLHttpRequest or XDomainRequest (basic implementation)
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/XDomainRequest
   * @see http://msdn.microsoft.com/en-us/library/ie/cc288060(v=vs.85).aspx
   *
   * @param options.url {String}
   * @param options.data {Object}
   * @param options.success {Function}
   * @param options.error {Function}
   */
  cors: function (options) {
    var req

    if (window.XDomainRequest) {
      req = new XDomainRequest()
      if (options.data) {
        req.open('GET', options.url + '?' + objectToUri(options.data))
      } else {
        req.open('GET', options.url)
      }
      if (options.error) {
        req.onerror = options.error
      }
      req.onload = function () {
        options.success(JSON.parse(req.responseText))
      }
      req.send()
    } else if (window.XMLHttpRequest) {
      req = new XMLHttpRequest()
      if (options.data) {
        req.open('GET', options.url + '?' + objectToUri(options.data), true)
      } else {
        req.open('GET', options.url, true)
      }
      req.onerror = function () {
        if (options.error) {
          options.error('server_error')
        }
      }
      req.onreadystatechange = function () {
        if (req.readyState === 4) {
          if (req.status >= 200 && req.status < 400) {
            var json
            try {
              json = JSON.parse(req.responseText)
            } catch (e) {
              if (options.error) {
                options.error('server_error')
              }
              return // Stop to avoid executing options.success
            }
            options.success(json) // Do not try/catch success callback
          } else if (req.status >= 400 && req.status < 500) {
            if (options.error) {
              if (req.getResponseHeader('content-type').indexOf('json') !== -1) {
                options.error(JSON.parse(req.responseText))
              } else {
                options.error('no_result')
              }
            }
          }
        }
      }
      req.send()
    } else {
      if (options.error) {
        options.error('cors_not_supported')
      }
    }
  }
}
